import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { makeAspectCrop, centerCrop, convertToPixelCrop } from "react-image-crop";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { Spinner, Alert } from "reactstrap";
import imageCompression from "browser-image-compression";
import Cookies from "js-cookie";

//^ http request
import { setProfileImgHandler, resetProfileImgHandler } from "../http/post-api";
import { queryClient } from "../http";

//^ lib
import { setCanvasPreview } from "../utils/lib";
import { base64toFile, responseErrorHandler } from "../utils/Utils";

//^ svg
import UploadFileSvg from "../components/svg/upload-file";
import UpdateFileDark from "../components/svg/upload-file-dark";

//^ @mui components
import { Box, Button, Container, DialogActions, DialogContent, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { RemoveRounded } from "@mui/icons-material";
import ImageWithPlaceholder from "../components/ui/auth-otp/image/image-with-placeholder";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 240;

const ImageCropper = ({
  originalImgSrc,
  updateAvatar,
  handleModalClose,
  getOriginalImgFile,
  onModalClose,
  alreadyImagePresent,
  dispatch,
  isLoading,
}) => {
  const theme = useTheme();

  const [crop, setCrop] = useState();
  const [imageSrc, setImageSrc] = useState(originalImgSrc || "");
  // eslint-disable-next-line
  const [dataUrl, setDataUrl] = useState("");
  const [originalImgFile, setOriginalImgFile] = useState(undefined);

  const imageRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const selectFileRef = useRef(null);

  useEffect(() => {
    if (originalImgFile) {
      getOriginalImgFile(originalImgFile);
    }
    // eslint-disable-next-line
  }, [originalImgFile]);

  //^ set profile mutation query
  const {
    isPending: setProfileImgIsPending,
    isError: setProfileImgIsError,
    error: setProfileImgError,
    mutate: setProfileImgMutate,
    reset: setProfileImgReset,
  } = useMutation({
    mutationKey: ["set-profile-img"],
    mutationFn: setProfileImgHandler,
    onSuccess: async (data) => {
      if (data.toast) {
        if (data.status) {
          const now = new Date();
          const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
          Cookies.set("profile_pic", data?.data?.profile_pic, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          toast.success(data.message);
          queryClient.invalidateQueries({
            queryKey: ["get-profile-image"],
            type: "active",
            exact: true,
          });

          queryClient.invalidateQueries({
            queryKey: ["get-profile"],
            type: "active",
            exact: true,
          });

          queryClient.invalidateQueries({
            queryKey: ["get-user-profile-user-layout"],
            type: "active",
            exact: true,
          });
          queryClient.invalidateQueries({
            queryKey: ["get-user-information"],
            type: "active",
            exact: true,
          });

          handleModalClose(false);
        } else {
          if (data?.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          } else {
            responseErrorHandler(true, data);
          }
        }
      }

      setProfileImgReset();
    },
  });

  //^ remove profile image mutation query
  const {
    isPending: resetImgIsPending,
    isError: resetImgIsError,
    error: resetImgError,
    mutate: resetImgMutate,
    reset: resetImgReset,
  } = useMutation({
    mutationKey: ["reset-profile-img-handler"],
    mutationFn: resetProfileImgHandler,
    onSuccess: async (data) => {
      if (data.toast) {
        if (data.status) {
          Cookies.remove("profile_pic", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
          toast.success(data.message);
          queryClient.invalidateQueries({
            queryKey: ["get-profile-image"],
          });

          queryClient.invalidateQueries({
            queryKey: ["get-profile"],
          });

          await queryClient.refetchQueries({
            queryKey: ["get-user-profile-user-layout"],
            type: "active",
            exact: true,
          });

          await queryClient.refetchQueries({
            queryKey: ["get-user-information"],
            type: "active",
            exact: true,
          });
          handleModalClose(false);
        } else {
          if (data?.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          } else {
            responseErrorHandler(true, data);
          }
        }
      }
      resetImgReset();
    },
  });

  useEffect(() => {
    if (resetImgIsError) {
      responseErrorHandler(true, resetImgError);
      resetImgReset();
    }
  }, [resetImgIsError, resetImgError, resetImgReset]);

  useEffect(() => {
    if (setProfileImgIsError) {
      responseErrorHandler(true, setProfileImgError);
      setProfileImgReset();
    }
  }, [setProfileImgIsError, setProfileImgError, setProfileImgReset]);

  useEffect(() => {
    updateAvatar(dataUrl, imageSrc);

    // eslint-disable-next-line
  }, [dataUrl, imageSrc, updateAvatar]);

  const handleSelectFile = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 768,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);

      const imageUrl = URL.createObjectURL(compressedFile);
      const imageElement = new Image();
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", () => {
        const { naturalWidth, naturalHeight } = imageElement;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          toast.error("Image must be at least 240 x 240 pixels.");
          setImageSrc(""); // Clear image source if the dimensions are too small
          URL.revokeObjectURL(imageUrl); // Clean up the object URL
        } else {
          setImageSrc(imageUrl); // Only set image source if dimensions are valid
          setOriginalImgFile(compressedFile); // Set the compressed file as the original image file
        }
      });

      // Clean up the object URL after it's no longer needed
      imageElement.addEventListener("error", () => {
        URL.revokeObjectURL(imageUrl);
        toast.error("Failed to load image.");
      });
    } catch (error) {
      console.log(error);
      toast.error("Image compression failed, please try again.");
    }
  };

  const handleReactCrop = (_crop, percentageCrop) => {
    setCrop(percentageCrop);
  };

  const handleImageLoad = (event) => {
    const { width, height } = event.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );

    const centeredCrop = centerCrop(crop, width, height);

    setCrop(centeredCrop);
  };

  const handleCropImage = async () => {
    const image = imageRef.current;
    const canvas = previewCanvasRef.current;

    if (!image || !canvas) {
      console.error("Image or canvas reference is missing.");
      return;
    }

    try {
      // Perform the cropping operation
      setCanvasPreview(image, canvas, convertToPixelCrop(crop, image.width, image.height));

      // Wait for the canvas to finish rendering
      await new Promise((resolve) => {
        canvas.toBlob(resolve);
      });

      // Get data URL from canvas
      const dataUrl = canvas.toDataURL();
      setDataUrl(dataUrl);

      // Convert data URL to file for cropped image
      const cropFile = base64toFile(dataUrl, "profile-img");

      dispatch({ type: "setLoading", payload: true });

      // Compress the cropped image
      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedCropFile = await imageCompression(cropFile, options);

      if (originalImgFile) {
        setProfileImgMutate({ cropFile: compressedCropFile, originalFile: originalImgFile });
      } else {
        dispatch({ type: "setLoading", payload: false });
        toast.error("No image file available to upload.");
      }
    } catch (error) {
      handleClose();
      dispatch({ type: "setLoading", payload: false });
      toast.success("Image Uploaded successfully.");
    }
  };

  function handleClose() {
    onModalClose(false);
  }

  function removePhotoHandler() {
    resetImgMutate({ resetImg: 1 });
  }

  return (
    <>
      <DialogContent dividers sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
        <Stack height={"100%"}>
          <Stack gap={"1rem"} alignItems={"center"} height={"100%"} justifyContent={"center"}>
            {imageSrc ? (
              <>
                <Container maxWidth={"md"} height={"100%"}>
                  <Stack height={"100%"}>
                    <Alert color="info">Image must be at least 240 x 240 pixels.</Alert>
                    <ReactCrop
                      crop={crop}
                      onChange={handleReactCrop}
                      circularCrop
                      keepSelection
                      aspect={ASPECT_RATIO}
                      minWidth={MIN_DIMENSION}
                    >
                      <ImageWithPlaceholder
                        ref={imageRef}
                        src={imageSrc}
                        alt="upload-img"
                        style={{ objectFit: "contain", maxHeight: "60vh", width: "100%" }}
                        onLoad={handleImageLoad}
                      />
                    </ReactCrop>
                  </Stack>
                </Container>
              </>
            ) : (
              <>
                {(originalImgSrc === null || !originalImgSrc) && (
                  <Stack height={"100%"}>
                    <Alert color="info">Image must be at least 240 x 240 pixels.</Alert>
                    {theme.palette.mode === "dark" ? <UpdateFileDark /> : <UploadFileSvg />}
                  </Stack>
                )}
              </>
            )}
            <>
              <Stack justifyContent={"center"} direction={"row"} gap={"0.625rem"} alignItems={"center"}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                  <Button
                    onClick={() => selectFileRef.current.click()}
                    size="small"
                    variant="contained"
                    sx={{ borderRadius: "100rem", whiteSpace: "nowrap" }}
                  >
                    Choose Photo
                  </Button>
                </Stack>
                <Box>
                  <input
                    type="file"
                    accept="image/*"
                    id="image-cropper"
                    onChange={handleSelectFile}
                    ref={selectFileRef}
                  />
                </Box>
              </Stack>
            </>

            <canvas
              ref={previewCanvasRef}
              style={{
                display: "none",
                width: "15rem",
                height: "15rem",
                objectFit: "contain",
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ padding: theme.spacing(2) }}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} gap={"1rem"} width={"100%"}>
          <Stack
            direction={"row"}
            width={"100%"}
            gap={"0.625rem"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              "@media (min-width: 66.5rem)": {
                justifyContent: "flex-start",
                alignItems: "flex-start",
              },
            }}
          >
            <Button
              startIcon={
                setProfileImgIsPending || isLoading ? (
                  <Spinner size={"sm"} style={{ borderWidth: "2px", color: "inherit" }} />
                ) : (
                  ""
                )
              }
              variant="contained"
              type="button"
              onClick={handleCropImage}
              disabled={setProfileImgIsPending || isLoading || !imageSrc}
              sx={{ whiteSpace: "nowrap" }}
            >
              {alreadyImagePresent ? "Update Profile" : "Upload Profile"}
            </Button>
            {alreadyImagePresent ? (
              <Box>
                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={removePhotoHandler}
                  disabled={resetImgIsPending}
                  sx={{ whiteSpace: "nowrap" }}
                  endIcon={<RemoveRounded />}
                  startIcon={
                    resetImgIsPending ? <Spinner size={"sm"} style={{ borderWidth: "2px", color: "inherit" }} /> : ""
                  }
                >
                  Remove Profile
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Stack>

          <Stack
            sx={{
              display: "none",
              "@media (min-width: 65.5rem)": {
                display: "flex",
              },
            }}
            direction={"row"}
            alignItems={"center"}
            gap={"0.625rem"}
          >
            <Button variant="contained" type="button" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  );
};

export default ImageCropper;
